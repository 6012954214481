import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { Link, useLocation, } from 'react-router-dom'
import "yup-phone";
import { Card, Button } from 'react-bootstrap';
import moment from 'moment';

export default function CampaignDetail() {    
       
    const location = useLocation();
    const campaignId = location.state.campaignId || 0 ;
    const access_token = localStorage.getItem("ApiToken");  
    const [campaign, setCampaign] = useState([]);
    const [isLoading, setIsLoading] = useState(false); 

    useEffect(() => {                        
        if (access_token) {
            setIsLoading(true); 
            axios({
                method: 'GET',
                url: `${process.env.REACT_APP_API_URL}/api/getcampaign/${campaignId}`,                
                headers: {                     
                    Authorization: `Bearer ${access_token}`
                }, 
            }).then(response => { 
                let arrResults = response.data;
                
                if (arrResults !== null && arrResults.length > 0) {
                    setCampaign(...arrResults);
                }         
                setIsLoading(false);      
            }).catch(error => {                
                console.log(error);
                setIsLoading(false);
            })             
        }        
    }, [access_token, campaignId])

    const styles = {
        cardLabel: {
            verticalAlign:"top", 
            paddingRight:10,
            fontWeight:700
        },
        cardValue: {
            verticalAlign:"top",
        }
    }

    return (
        !isLoading?
        <React.Fragment>   
            <div className="container">
                <div className="card-columns">
                    <Card>
                        <Card.Header style={{backgroundColor:"#5581ab", color:"#FFFFFF"}}>Campaign</Card.Header>
                        <Card.Title>
                            <p style={{paddingLeft:10,}}>
                                {campaign.CampaignName}
                            </p>
                        </Card.Title>
                        <Card.Body>
                            <table>
                                <tbody>
                                <tr>
                                    <td style={styles.cardLabel}>Identifier:</td>
                                    <td style={styles.cardValue}>{campaign.CampaignIdentifier}</td>
                                </tr>
                                <tr>
                                    <td style={styles.cardLabel}>Description:</td>
                                    <td style={styles.cardValue}>{campaign.CampaignDescription != null && campaign.CampaignDescription !== ''? campaign.CampaignDescription : ''}</td>
                                </tr>
                                <tr>
                                    <td style={{columnSpan:2}}>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td style={styles.cardLabel}>Start Date:</td>
                                    <td style={styles.cardValue}>{moment(campaign.StartDate).format('MM/DD/yyyy')}</td>
                                </tr>
                                <tr>
                                    <td style={styles.cardLabel}>End Date:</td>
                                    <td style={styles.cardValue}>{campaign.EndDate != null? moment(campaign.EndDate).format('MM/DD/yyyy') : ''}</td>
                                </tr>
                                <tr>
                                    <td style={{columnSpan:2}}>&nbsp;</td>
                                </tr>
                                </tbody>
                            </table>
                            <Link 
                                to={{
                                    pathname: '/campaignsave',
                                    state: { campaignId: campaignId },                                    
                                }}
                            >
                                <Button variant="primary" >
                                    Edit Campaign
                                </Button>
                            </Link> 
                        </Card.Body>
                    </Card> 
                </div>
            </div> 
        </React.Fragment> 
        : 
        <div class="text-center" style={{width:"100%", height:"100%", }}>
            <div class="spinner-border" role="status" style={{ marginTop:"100px", width:"5rem", height:"5rem",}}>
                <span class="sr-only">Loading...</span>
            </div>
        </div>         
    )
}
