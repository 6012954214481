import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Link, useLocation, } from 'react-router-dom'
import "yup-phone";
import { Card, Button } from 'react-bootstrap';
import moment from 'moment';

export default function UserDetail() {    
   
    const access_token = localStorage.getItem("ApiToken");   
    const location = useLocation();
    const systemUserId = localStorage.getItem("RoleId") === "1"? location.state.systemUserId : localStorage.getItem("SystemUserId");  
    const [user, setUser] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    
    const styles = {
        cardLabel: {
            verticalAlign:"top", 
            paddingRight:10,
            fontWeight:700
        },
        cardValue: {
            verticalAlign:"top",
        }
    }

    useEffect(() => {                     
        if (access_token) {
            setIsLoading(true);
            axios({
                method: 'GET',
                url: `${process.env.REACT_APP_API_URL}/api/getsystemuser/${systemUserId}`,                
                headers: {                     
                    Authorization: `Bearer ${access_token}`
                }, 
            }).then(response => { 
                let arrResults = response.data;
                
                if (arrResults !== null && arrResults.length > 0) {
                    setUser(...arrResults);
                }
                setIsLoading(false);
            }).catch(error => {
                console.log(error);
                setIsLoading(false);
            })             
        }        
    }, [access_token, systemUserId])

    return (
        !isLoading?
        <React.Fragment>   
            <div className="container">
                <div className="card-columns">
                    <Card>
                        <Card.Header style={{backgroundColor:"#5581ab", color:"#FFFFFF"}}>User Account</Card.Header>
                        <Card.Title>
                            <p style={{paddingLeft:10,}}>
                                {user.FirstName + ' '} 
                                {user.MiddleInitial != null && user.MiddleInitial !== ''? ' ' + user.MiddleInitial + ' ' : ''}
                                {user.LastName}
                            </p>
                        </Card.Title>
                        <Card.Body>
                            <table>
                            <tbody>
                                <tr>
                                    <td style={styles.cardLabel}>Email Address:</td>
                                    <td style={styles.cardValue}>{user.EmailAddress != null? user.EmailAddress : ''}</td>
                                </tr>
                                <tr>
                                    <td style={styles.cardLabel}>Login:</td>
                                    <td style={styles.cardValue}>{user.LoginId != null? user.LoginId : ''}</td>
                                </tr>
                                <tr>
                                    <td style={styles.cardLabel}>Role:</td>
                                    <td style={styles.cardValue}>{user.Role != null? user.Role : ''}</td>
                                </tr>
                                <tr>
                                    <td style={styles.cardLabel}>End Date:</td>
                                    <td style={styles.cardValue}>{user.EndDate != null? moment(user.EndDate).format('MM/DD/yyyy') : ''}</td>
                                </tr>
                                <tr>
                                    <td style={{columnSpan:2}}>&nbsp;</td>
                                </tr>
                            </tbody>
                            </table>
                            <Link 
                                to={{
                                    pathname: '/usersave',
                                    state: { systemUserId: systemUserId },                                    
                                }}
                            >
                                <Button variant="primary" >
                                    Edit User
                                </Button>
                            </Link> 
                        </Card.Body>
                    </Card> 
                </div>
            </div> 
        </React.Fragment>  
        : 
        <div class="text-center" style={{width:"100%", height:"100%", }}>
            <div class="spinner-border" role="status" style={{ marginTop:"100px", width:"5rem", height:"5rem",}}>
                <span class="sr-only">Loading...</span>
            </div>
        </div>       
    )
}
