import React from 'react';
import LeadSearch from '../components/LeadSearch';
import PageTitle from '../components/PageTitle';
import { Link } from 'react-router-dom';

export default function Leads() {    
    return (
        <React.Fragment>     
            <PageTitle>
                <div>
                    <div className="page-title">
                        Leads                       
                    </div>
                </div>
            </PageTitle>
            <p style={{ paddingLeft:10, paddingTop:10 }}>
                <Link className="btn btn-primary"
                    to={{
                        pathname: `leadsave`,
                        state: { leadId: 0 },                                    
                    }}
                >New Lead</Link>
            </p>
            <LeadSearch />               
        </React.Fragment>
    )
}
