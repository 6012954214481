import React, { Fragment, useState, useEffect } from 'react'
import { Link, Redirect, useLocation } from 'react-router-dom'
import { Formik } from 'formik';
import Form from "react-bootstrap/Form";
import * as Yup from 'yup';
import axios from 'axios'
import Alert from 'react-bootstrap/Alert';
import moment from 'moment';

export default function CampaignSave() {    
   
    const location = useLocation();
    const campaignId = location.state.campaignId || 0 ;
    const [goToCampaigns, setGoToCampaigns] = useState(false);
    const access_token = localStorage.getItem("ApiToken");  
    const [campaign, setCampaign] = useState([]);
    const [isLoading, setIsLoading] = useState(false); 

    useEffect(() => {                     
        if (access_token) {
            setIsLoading(true);
            axios({
                method: 'GET',
                url: `${process.env.REACT_APP_API_URL}/api/getcampaign/${campaignId}`,                
                headers: {                     
                    Authorization: `Bearer ${access_token}`
                }, 
            }).then(response => { 
                let arrResults = response.data;
                
                if (arrResults !== null && arrResults.length > 0) {
                    setCampaign(...arrResults);
                }             
                setIsLoading(false);
            }).catch(error => {                
                console.log(error);
                setIsLoading(false);
            })             
        }        
    }, [access_token, campaignId])

    const CampaignSchema = Yup.object().shape({
        campaignName: Yup.string()
            .required('Campaign Name Is Required'),
        campaignIdentifier: Yup.string()
            .required('Campaign Identifier Is Required'),
        startDate: Yup.date()
            .required('Start Date Is Required') 
            //.format('MM/DD/yyyy'), 
    });
   
    function handleFormSubmission(values, actions) { 
        const access_token = localStorage.getItem("ApiToken");              
        if (access_token) {
            axios({
                method: 'POST',
                url: `${process.env.REACT_APP_API_URL}/api/savecampaign`,                
                headers: {                     
                    Authorization: `Bearer ${access_token}`
                },  
                data: {
                    "CampaignId": values.campaignId,
                    "CampaignName": values.campaignName,
                    "CampaignIdentifier": values.campaignIdentifier,
                    "CampaignDescription": values.campaignDescription,
                    "StartDate": values.startDate,
                    "EndDate": values.endDate ,
                    "CreatedDate": "2021-05-13T13:13:48.4644469-04:00"
                    }        
            }).then(response => { 
               setGoToCampaigns(true);
            }).catch(error => {
                console.log(error);
            }) 
        }
    }
    return (
        !isLoading?
        <React.Fragment>     
            {goToCampaigns && <Redirect to={{
                                        pathname: '/campaigns',
                                        state: { campaignId: campaignId },                                    
                                    }} />}                                        
            <p className="lead mb-5" style={{ padding:20 }}>Campaign Update</p>
                <Formik enableReinitialize={true} 
                    initialValues={{                                        
                        campaignId: campaignId > 0? campaign.CampaignId : 0,
                        campaignName: campaignId > 0 && campaign.CampaignName != null? campaign.CampaignName : '',
                        campaignIdentifier: campaignId > 0 && campaign.CampaignIdentifier != null? campaign.CampaignIdentifier : '',
                        campaignDescription: campaignId > 0 && campaign.CampaignDescription != null? campaign.CampaignDescription : '',
                        startDate: campaignId > 0 && campaign.StartDate != null? moment(campaign.StartDate).format('yyyy-MM-DD') : '',
                        endDate: campaignId > 0 && campaign.EndDate != null? moment(campaign.EndDate).format('yyyy-MM-DD') : '',
                    }}
                    validationSchema={CampaignSchema}
                    onSubmit={(values, actions) => {
                        // same shape as initial values
                        handleFormSubmission(values, actions);
                    }}
                >
                    {({ 
                        isSubmitting,
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        values,
                        touched,
                        errors,
                }) => (
                    <Form 
                    onSubmit={handleSubmit}
                    autoComplete="off"
                    >
                        <div className="form_container" style={{ padding:20 }}>
                            <div className="form-group">
                                <label htmlFor="campaignName" className="semibold-font"><span style={{ color: "#ff0000" }}>*</span> Campaign Name</label>
                                <Form.Control 
                                    type="text" 
                                    id="campaignName" 
                                    name="campaignName" 
                                    placeholder="Campaign Name" 
                                    value={values.campaignName}
                                    onChange={handleChange}
                                    onBlur={handleBlur} 
                                    isInvalid={errors.campaignName && touched.campaignName}
                                    autoComplete="campaignName"
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.campaignName}
                                </Form.Control.Feedback>
                            </div>
                            <div className="form-group">
                                <label htmlFor="campaignIdentifier" className="semibold-font"><span style={{ color: "#ff0000" }}>*</span> Campaign Identifier</label>
                                <Form.Control 
                                    type="text" 
                                    id="campaignIdentifier" 
                                    name="campaignIdentifier"
                                    placeholder="Campaign Identifier" 
                                    value={values.campaignIdentifier}
                                    onChange={handleChange}
                                    onBlur={handleBlur} 
                                    isInvalid={errors.campaignIdentifier && touched.campaignIdentifier}
                                    autoComplete="campaignIdentifier"
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.campaignIdentifier}
                                </Form.Control.Feedback>
                            </div>
                            <div className="form-group">
                                <label htmlFor="campaignDescription" className="semibold-font"><span style={{ paddingLeft:10 }}>Campaign Description</span></label>
                                <Form.Control as="textarea" rows={3}                                                     
                                    type="textarea" 
                                    id="campaignDescription" 
                                    name="campaignDescription" 
                                    placeholder="Campaign Description" 
                                    value={values.campaignDescription}
                                    onChange={handleChange}
                                    onBlur={handleBlur} 
                                    isInvalid={errors.campaignDescription && touched.campaignDescription}
                                    autoComplete="campaignDescription"
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.campaignDescription}
                                </Form.Control.Feedback>
                            </div>
                            <div className="form-group">
                                <label htmlFor="startDate" className="semibold-font"><span style={{ color: "#ff0000" }}>*</span> Start Date</label>
                                <Form.Control 
                                    type="date" 
                                    id="startDate" 
                                    name="startDate" 
                                    placeholder="Start Date" 
                                    value={values.startDate}
                                    onChange={handleChange}
                                    onBlur={handleBlur} 
                                    isInvalid={errors.startDate && touched.startDate}
                                    autoComplete="startDate"
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.startDate}
                                </Form.Control.Feedback>
                            </div>
                            <div className="form-group">
                                <label htmlFor="endDate" className="semibold-font"><span style={{ paddingLeft:10 }}>End Date</span></label>
                                <Form.Control 
                                    type="date" 
                                    id="endDate" 
                                    name="endDate" 
                                    placeholder="End Date" 
                                    value={values.endDate}
                                    onChange={handleChange}
                                    onBlur={handleBlur} 
                                    isInvalid={errors.endDate && touched.endDate}
                                    autoComplete="endDate"
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.endDate}
                                </Form.Control.Feedback>
                            </div>
                            <div className="row mt-5">
                                <div className="col-12 text-center mb-3">
                                    <button type="submit" className="btn btn-primary">
                                    {isSubmitting ? (
                                        <Fragment>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Please Wait...
                                        </Fragment>
                                    ) : 'Save Campaign'}
                                    </button>
                                </div>
                                <div className="col-12 text-center my-3">
                                    <Link to={{
                                        pathname: '/campaigns',
                                        state: { campaignId: campaignId },                                    
                                    }}><strong>Cancel</strong></Link>
                                </div>                                                
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
            <Alert 
                variant="danger" 
                onClose={() => this.closeAlert()} 
                dismissible
                show={false}
            >
                { "" }
            </Alert> 
        </React.Fragment>  
        : 
        <div class="text-center" style={{width:"100%", height:"100%", }}>
            <div class="spinner-border" role="status" style={{ marginTop:"100px", width:"5rem", height:"5rem",}}>
                <span class="sr-only">Loading...</span>
            </div>
        </div>      
    )
}
