import React, { useState } from 'react';
import { NavLink, Link } from 'react-router-dom';
import CampaignLogo from '../assets/images/login-logo.png';
import NavigationData from '../data/NavigationData';

export default function MainLayout({ children }) {
    const IS_PRODUCTION = process.env.REACT_APP_IS_PRODUCTION;
    const APP_NAME = process.env.REACT_APP_Name;
    const [ showSideBar, setShowSideBar ] = useState(true);
    const [ showMobleNav, setShowMobileNav ] = useState(false);
    const openClassName = `app-container app-theme-white body-tabs-shadow fixed-sidebar fixed-header`;
    const closeClassName = `app-container app-theme-white body-tabs-shadow fixed-sidebar fixed-header closed-sidebar`;    
    const loginRoleId = localStorage.getItem("RoleId");
    
    let navArray = [];

    if (loginRoleId === "1") {
        navArray = new NavigationData().NavigationDataAdmin();
    } else {
        navArray = new NavigationData().NavigationDataCampaignManager();
    }
        
    return (
        <div className={showSideBar ? openClassName : closeClassName}>
            <div className="app-header header-shadow">
                <div className="app-header__logo">
                    <div className="logo">
                        <img src={ CampaignLogo } className={ showSideBar === true? "img-fluid" : "collapse" } alt={ APP_NAME } width="60" />
                        <span className={ showSideBar === true? "main-header" : "collapse" } >{ APP_NAME }                        
                        </span>
                    </div>
                    <div className="header__pane ml-auto">
                        <div>
                            <button 
                                type="button" 
                                className="hamburger close-sidebar-btn hamburger--elastic" 
                                data-classname="closed-sidebar"
                                onClick={() => setShowSideBar(!showSideBar)}
                                >
                                <span className="hamburger-box">
                                    <span className="hamburger-inner"></span>
                                </span>
                            </button>                            
                        </div>
                    </div>
                </div>
                <div className="app-header__mobile-menu">
                    <div>
                        <button 
                            type="button" 
                            data-target="navbarTogglerDemo03" 
                            data-toggle="collapse" 
                            className="hamburger hamburger--elastic mobile-toggle-nav"
                            onClick={() => setShowMobileNav(!showMobleNav)}
                            >
                            <span className="hamburger-box">
                                <span className="hamburger-inner"></span>
                            </span>                           
                        </button> 
                    </div>                    
                    <div className="mobile-header-div">
                        <p className="mobile-header">{ APP_NAME }
                        { IS_PRODUCTION === "false"? <React.Fragment><br /><span style={{ color:'#ff0000', fontSize:16, textAlign:'center' }}>DEVELOPMENT</span></React.Fragment> : ''}
                        </p>                         
                    </div>
                </div>                   
                <div className="app-header__content" style={{ backgroundColor: IS_PRODUCTION === "false"? '#ff0000' : "#ffffff"}}>
                    { IS_PRODUCTION === "false"? <React.Fragment><span style={{ color:'#ffffff', fontSize:16, textAlign:'center' }}>DEVELOPMENT</span></React.Fragment> : ''}                    
                    <div className="app-header-left">                              
                    </div>
                    <div className="app-header-right">
                        <div className="header-btn-lg pr-0">
                            <div className="widget-content p-0">
                                <div className="widget-content-wrapper">
                                    <div className="widget-content-left">
                                        <div className="btn-group">
                                            <button data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="p-0 btn">
                                                <img width="42" className="rounded-circle" src={require("../assets/assets/images/avatars/1.jpg")} alt="" />
                                                <i className="fa fa-angle-down ml-2 opacity-8"></i>
                                            </button>
                                            <div tabIndex="-1" role="menu" aria-hidden="true" className="dropdown-menu dropdown-menu-right">
                                                <Link to="/user-details" className="dropdown-item">User Account</Link>
                                                <Link to="/settings" className="dropdown-item">Settings</Link>                                                
                                                <div tabIndex="-1" className="dropdown-divider"></div>
                                                <Link to="/logout" className="dropdown-item">Logout</Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="widget-content-left  ml-3 header-user-info">
                                        <div className="widget-heading">
                                            
                                        </div>
                                        <div className="widget-subheading">
                                            { APP_NAME }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>        
                    </div>
                </div>
            </div>
            <div className="app-main">
                <div className={ showMobleNav === true? "" : "collapse" } >
                    <ul style={{ backgroundColor: '#ffffff'}} className="vertical-nav-menu">
                        {                            
                            navArray.map(navElement => {     
                                                            
                                return <li key={ navElement.navId }>
                                            <NavLink exact to={ navElement.navTo } activeClassName="mm-active">
                                                <i className={navElement.className }></i>
                                                { navElement.menuLabel }
                                            </NavLink>
                                        </li>
                            })
                        }                        
                    </ul>
                </div>  
                <div className="app-sidebar sidebar-shadow">
                    <div className="app-header__logo">
                        <div className="logo-src"></div>
                        <div className="header__pane ml-auto">
                            <div>
                                <button type="button" className="hamburger close-sidebar-btn hamburger--elastic" data-classname="closed-sidebar">
                                    <span className="hamburger-box">
                                        <span className="hamburger-inner"></span>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="app-header__mobile-menu">
                        <div>
                            <button type="button" className="hamburger hamburger--elastic mobile-toggle-nav">
                                <span className="hamburger-box">
                                    <span className="hamburger-inner"></span>
                                </span>
                            </button>
                        </div>
                    </div>
                    <div className="app-header__menu">
                        <span>
                            <button type="button" className="btn-icon btn-icon-only btn btn-primary btn-sm mobile-toggle-header-nav">
                                <span className="btn-icon-wrapper">
                                    <i className="fa fa-ellipsis-v fa-w-6"></i>
                                </span>
                            </button>
                        </span>
                    </div>    
                    <div className="scrollbar-sidebar">
                        <div className="app-sidebar__inner">
                            <ul className="vertical-nav-menu">                                
                                {                            
                                    navArray.map(navElement => {                                 
                                        return <li key={ navElement.navId }><NavLink exact to={ navElement.navTo } activeClassName="mm-active">
                                            <i className={navElement.className }></i>
                                            { navElement.menuLabel }
                                        </NavLink></li>
                                    })
                                }       
                            </ul>
                        </div>
                    </div>
                </div>    
                <div className="app-main__outer">
                    <div className="app-main__inner">
                        { children }
                    </div>
                    <div className="app-wrapper-footer">
                        <div className="app-footer">
                            <div className="app-footer__inner">
                                <div className="app-footer-left">
                                    {/* <ul className="nav">
                                        <li className="nav-item">
                                            <a href="javascript:void(0);" className="nav-link">
                                                Footer Link 1
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="javascript:void(0);" className="nav-link">
                                                Footer Link 2
                                            </a>
                                        </li>
                                    </ul> */}
                                </div>
                                <div className="app-footer-right">
                                    <p>Copyright {new Date().getFullYear()} Nichols Software</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
