import React  from 'react';
import CampaignTable from '../components/CampaignTable';
import '../index.css';
import '../table.css'

function CrudSearch () {        
       
    return (
        <React.Fragment>
            <CampaignTable></CampaignTable>
        </React.Fragment>
    );
}

export default CrudSearch;