import React from 'react';
import CampaignSearch from '../components/CampaignSearch';
import PageTitle from '../components/PageTitle';
import { Link } from 'react-router-dom';

export default function Campaigns() {    
    return (
        <React.Fragment>     
            <PageTitle>
                <div>
                    <div className="page-title">
                        Campaigns                       
                    </div>
                </div>
            </PageTitle>
            <p style={{ paddingLeft:10 }}>
                <Link className="btn btn-primary"
                    to={{
                        pathname: `campaignsave/`,
                        state: { campaignId: 0 },                                    
                    }}
                >New Campaign</Link>
            </p>
            <CampaignSearch />               
        </React.Fragment>
    )
}
