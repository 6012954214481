import React, { Fragment, useState, useEffect } from 'react'
import { Link, Redirect, useLocation } from 'react-router-dom'
import { Formik } from 'formik';
import Form from "react-bootstrap/Form";
import * as Yup from 'yup';
import axios from 'axios'
import Alert from 'react-bootstrap/Alert';
import moment from 'moment';

export default function UserSave() {    
    const access_token = localStorage.getItem("ApiToken");   
    const location = useLocation();    
    const loginSystemUserId = localStorage.getItem("SystemUserId");
    const loginRoleId = parseInt(localStorage.getItem("RoleId"));
    const [systemUser, setSystemUser] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    let systemUserId = 0;
    if (location.state != null) {
        systemUserId = location.state.systemUserId;
    } else {
        systemUserId = loginSystemUserId;
    }

    const [goToUsers, setGoToUsers] = useState(false);

    useEffect(() => {                     
        if (access_token) {
            setIsLoading(true);
            axios({
                method: 'GET',
                url: `${process.env.REACT_APP_API_URL}/api/getsystemuser/${systemUserId}`,                
                headers: {                     
                    Authorization: `Bearer ${access_token}`
                }, 
            }).then(response => { 
                let arrResults = response.data;
                
                if (arrResults !== null && arrResults.length > 0) {
                    setSystemUser(...arrResults);
                }
                setIsLoading(false);
            }).catch(error => {
                console.log(error);
                setIsLoading(false);
            })             
        }        
    }, [access_token, systemUserId])

    const userSchema = Yup.object().shape({
        firstName: Yup.string()
            .required('First Name is Required'),
        lastName: Yup.string()
            .required('Last Name is Required'),
        loginId: Yup.string()
            .required('Login Id is Required'),
        roleId: Yup.string()
            .required("Role is Required")
    });

    let displayPasswordNote = false;

    if (systemUserId > 0) {
        displayPasswordNote = true;
    }
    let passwordNote = '(The password remains unchanged if left blank)';
    
    const styles = {
        passwordNote: { color: '#000066', fontSize: 12, },
    };

    function handleFormSubmission(values, actions) {   
        const access_token = localStorage.getItem("ApiToken");                
        if (access_token) {
            setIsLoading(true);
            axios({
                method: 'POST',
                url: `${process.env.REACT_APP_API_URL}/api/savesystemuser`,                
                headers: {                     
                    Authorization: `Bearer ${access_token}`
                },  
                data: {
                    "SystemUserId": values.systemUserId,
                    "FirstName": values.firstName,
                    "LastName": values.lastName,
                    "EmailAddress": values.emailAddress,
                    "LoginId": values.loginId,
                    "Password": values.password,
                    "Hash": "QEA=",
                    "Salt": "QEA=",
                    "Role": values.role,
                    "RoleId": values.roleId,
                    "EndDate": values.endDate ,
                    "CreatedDate": "2021-05-13T13:13:48.4644469-04:00"
                    }        
            }).then(response => {  
                setGoToUsers(true);
                setIsLoading(false);
            }).catch(error => {
                console.log(error);
                setIsLoading(false);
            }) 
        }
    }
    
    return (
        !isLoading?
        <React.Fragment> 
            {goToUsers && loginRoleId === 1 && <Redirect to={{
                                                    pathname: '/user',
                                                    state: { systemUserId: systemUserId },                                    
                                                }} />}  
            {goToUsers && loginRoleId !== 1 && <Redirect to="/home" />}                           
            <p className="lead mb-5" style={{ padding:20 }}>User Update</p>
                <Formik                     
                    enableReinitialize={true} 
                    initialValues={{                        
                                        
                        systemUserId: systemUserId > 0 && systemUser.SystemUserId != null? systemUser.SystemUserId : 0,
                        firstName: systemUserId > 0 && systemUser.FirstName != null? systemUser.FirstName : '',
                        lastName: systemUserId > 0 && systemUser.LastName != null? systemUser.LastName : '',
                        emailAddress: systemUserId > 0 && systemUser.EmailAddress != null? systemUser.EmailAddress : '',
                        loginId: systemUserId > 0 && systemUser.LoginId != null? systemUser.LoginId : '',
                        roleId: systemUserId > 0 && systemUser.RoleId != null? systemUser.RoleId : 0,
                        endDate: systemUserId > 0 && systemUser.EndDate != null? moment(systemUser.EndDate).format('yyyy-MM-DD') : '',                     
                    }}
                    validationSchema={userSchema}
                    onSubmit={(values, actions) => {
                        // same shape as initial values
                        handleFormSubmission(values, actions);
                    }}
                >
                    {({ 
                        isSubmitting,
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        values,
                        touched,
                        errors,
                }) => (
                    <Form 
                    onSubmit={handleSubmit}
                    autoComplete="off"
                    >
                        <input autoComplete="false" name="hidden" type="text" className="hidden" sytle={{ display: 'none' }}></input>
                        <div className="form_container" style={{ padding:20 }}>
                            <div className="form-group">
                                <label htmlFor="firstName" className="semibold-font"><span style={{ color: "#ff0000" }}>*</span> First Name</label>
                                <Form.Control 
                                    type="text" 
                                    id="firstName" 
                                    name="firstName" 
                                    placeholder="First Name" 
                                    value={values.firstName}
                                    onChange={handleChange}
                                    onBlur={handleBlur} 
                                    isInvalid={errors.firstName && touched.firstName}
                                    autoComplete="firstName"
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.firstName}
                                </Form.Control.Feedback>
                            </div>
                            <div className="form-group">
                                <label htmlFor="lastName" className="semibold-font"><span style={{ color: "#ff0000" }}>*</span> Last Name</label>
                                <Form.Control 
                                    type="text" 
                                    id="lastName" 
                                    name="lastName"
                                    placeholder="Last Name" 
                                    value={values.lastName}
                                    onChange={handleChange}
                                    onBlur={handleBlur} 
                                    isInvalid={errors.lastName && touched.lastName}
                                    autoComplete="lastName"
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.lastName}
                                </Form.Control.Feedback>
                            </div>
                            <div className="form-group">
                                <label htmlFor="emailAddress" className="semibold-font"><span style={{ paddingLeft:10 }}>Email Address</span></label>
                                <Form.Control                                                   
                                    type="text" 
                                    id="emailAddress" 
                                    name="emailAddress" 
                                    placeholder="Email Address" 
                                    value={values.emailAddress}
                                    onChange={handleChange}
                                    onBlur={handleBlur} 
                                    isInvalid={errors.emailAddress && touched.emailAddress}
                                    autoComplete="emailAddress"
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.emailAddress}
                                </Form.Control.Feedback>
                            </div>
                            <div className="form-group">
                                <label htmlFor="loginId" className="semibold-font"><span style={{ color: "#ff0000" }}>*</span> Login Id</label>
                                <Form.Control 
                                    type="text" 
                                    id="loginId" 
                                    name="loginId" 
                                    placeholder="Login Id" 
                                    value={values.loginId}
                                    onChange={handleChange}
                                    onBlur={handleBlur} 
                                    isInvalid={errors.loginId && touched.loginId}
                                    autoComplete="loginId"
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.loginId}
                                </Form.Control.Feedback>
                            </div>
                            <div className="form-group">
                                <label htmlFor="password" className="semibold-font"><span style={{ color: "#0000ff" }}>*</span> Password</label>
                                { (displayPasswordNote === true)? <span style={ styles.passwordNote }> { passwordNote }</span>  : '' }
                                <Form.Control 
                                    type="password" 
                                    id="password" 
                                    name="password" 
                                    placeholder="Password" 
                                    value={values.password}
                                    onChange={handleChange}
                                    onBlur={handleBlur} 
                                    isInvalid={errors.password && touched.password}
                                    autoComplete="password"
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.password}
                                </Form.Control.Feedback>
                            </div>
                            <div className="form-group">
                                <label htmlFor="role" className="semibold-font"><span style={{ color: "#ff0000" }}>*</span> Role</label>
                                <Form.Control as="select"                                    
                                    id="roleId" 
                                    name="roleId" 
                                    placeholder="Role" 
                                    value={values.roleId}
                                    onChange={handleChange}
                                    onBlur={handleBlur} 
                                    isInvalid={errors.roleId && touched.roleId}
                                    autoComplete="roleId"
                                >   
                                    <option value="" label="--- Select a Role ---" />
                                    <option value="1" label="Administrator" />
                                    <option value="5" label="Campaign Manager" />
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    {errors.roleId}
                                </Form.Control.Feedback>
                            </div>
                            <div className="form-group">
                                <label htmlFor="endDate" className="semibold-font"><span style={{ paddingLeft:10 }}>End Date</span></label>
                                <Form.Control 
                                    type="date" 
                                    id="endDate" 
                                    name="endDate" 
                                    placeholder="End Date" 
                                    value={values.endDate}
                                    onChange={handleChange}
                                    onBlur={handleBlur} 
                                    isInvalid={errors.endDate && touched.endDate}
                                    autoComplete="endDate"
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.endDate}
                                </Form.Control.Feedback>
                            </div>
                            <div className="row mt-5">
                                <div className="col-12 text-center mb-3">
                                    <button type="submit" className="btn btn-primary">
                                    {isSubmitting ? (
                                        <Fragment>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Please Wait...
                                        </Fragment>
                                    ) : 'Save User'}
                                    </button>
                                </div>
                                <div className="col-12 text-center my-3">
                                        <Link to={{
                                            pathname: '/user',
                                            state: { systemUserId: systemUserId },                                    
                                        }}><strong>Cancel</strong></Link>
                                </div>                                                
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
            <Alert 
                variant="danger" 
                onClose={() => this.closeAlert()} 
                dismissible
                show={false}
            >
                { "" }
            </Alert> 
        </React.Fragment>   
        : 
        <div class="text-center" style={{width:"100%", height:"100%", }}>
            <div class="spinner-border" role="status" style={{ marginTop:"100px", width:"5rem", height:"5rem",}}>
                <span class="sr-only">Loading...</span>
            </div>
        </div>      
    )
}
