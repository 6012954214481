import React from 'react';
import SalesFunnelStats from '../components/SalesFunnelStats';

export default function Home() {

    return (
        <React.Fragment>  
            <SalesFunnelStats />
        </React.Fragment>
    )
}
