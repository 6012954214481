import React, { useState, } from "react";
import axios from 'axios';
import "yup-phone";
import moment from 'moment';
import '../index.css';
import '../table.css'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"
import NumberFormat from "react-number-format";

export function AppointmentReportTable() {
    const access_token = localStorage.getItem("ApiToken");   
    const [appointmentReport, setAppointmentReport] = useState([]); 
    const [isLoading, setIsLoading] = useState(false); 
    const today = new Date();
    const [startDate, setStartDate] = useState(new Date(today.getFullYear(), today.getMonth() - 1, today.getDate()));
    const [endDate, setEndDate] = useState(new Date(today.getFullYear(), today.getMonth() + 1, today.getDate()));
    const [virtualConsultation, setVirtualConsultation] = useState(true);
    const [officeConsultation, setOfficeConsultation] = useState(true);
    const [surgery, setSurgery] = useState(false);
    const [sutureRemoval, setSutureRemoval] = useState(false);
    const [followUp, setFollowUp] = useState(false);
    
    function getReport() {  
        if (access_token) {  
            setIsLoading(true);           
            axios({
                method: 'POST',
                url: `${process.env.REACT_APP_API_URL}/api/getappointmentreport`,                
                headers: {                     
                    Authorization: `Bearer ${access_token}`
                }, 
                data: {
                    "AppointmentStartDate": startDate, 
                    "AppointmentEndDate": endDate,
                    "VirtualConsultation": virtualConsultation,
                    "OfficeConsultation": officeConsultation,
                    "Surgery": surgery,
                    "SutureRemoval": sutureRemoval,
                    "FollowUp": followUp
                }
            }).then(response => { 
                let arrResults = response.data;
                setAppointmentReport(arrResults); 
                setIsLoading(false);         
            }).catch(error => {                
                console.log(error);
                setIsLoading(false);
            })             
        }   
    }

    return (
        <React.Fragment>
            <div style={{ maxWidth:'370px', }}>
                <p style={{ width:'180px', float:'left', fontSize:'11px', fontWeight:'500', textTransform:'none', }}>
                    <strong>Start Date</strong>
                    <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        selectsStart
                        startDate={startDate}
                        endDate={endDate}
                    />
                </p>
                <p style={{ width:'180px', float:'left', fontSize:'11px', fontWeight:'500', textTransform:'none',}}>
                    <strong>End Date</strong>
                    <DatePicker
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                        minDate={startDate}
                    />
                </p>
                <ul style={{ padding:'0px', fontSize:'11px', fontWeight:'500', textTransform:'none', }}>
                    <li style={{ display:'inline-block', marginRight:'10px' }}>
                        <input 
                            type="checkbox" 
                            id="chkVirtualConsultation" 
                            value={ virtualConsultation }
                            defaultChecked={ virtualConsultation }                      
                            onChange={e => { setVirtualConsultation(e.target.checked); }} 
                        /> Virtual Consultation</li>
                    <li style={{ display:'inline-block', marginRight:'10px' }}>
                        <input 
                            type="checkbox" 
                            id="chkOfficeConsultation" 
                            value={ officeConsultation }
                            defaultChecked={ officeConsultation }                      
                            onChange={e => { setOfficeConsultation(e.target.checked); }} 
                        /> Office Consultation</li>
                    <li style={{ display:'inline-block', marginRight:'10px' }}>
                        <input 
                            type="checkbox" 
                            id="chkSurgery" 
                            value={ surgery }
                            defaultChecked={ surgery } 
                            onChange={e => { setSurgery(e.target.checked); }}
                        /> Surgery</li>
                    <li style={{ display:'inline-block', marginRight:'10px' }}>
                        <input 
                            type="checkbox" 
                            id="chkSutureRemoval" 
                            value={ sutureRemoval }
                            defaultChecked={ sutureRemoval } 
                            onChange={e => { setSutureRemoval(e.target.checked) }}
                        /> Suture Removal</li>
                     <li style={{ display:'inline-block', marginRight:'10px' }}>
                        <input 
                            type="checkbox" 
                            id="chkFollowUp" 
                            value={ followUp }
                            defaultChecked={ followUp }                      
                            onChange={e => { setFollowUp(e.target.checked); }} 
                        /> Follow Up</li>
                </ul>
                <p style={{ width:'100px', }}>
                    <button type={'button'} onClick={ getReport }>
                        Submit    
                    </button>
                </p>                
            </div>           
            { !isLoading? <React.Fragment>                
                <div className="table-responsive">
                    <table className="table table-striped table-hover responsive">
                        <thead>
                            <tr style={{ backgroundColor:'#3f7eb1', color:'#ffffff', fontSize:'11px', textTransform:'none', }}>
                                <td>Lead Name</td>
                                <th>Appointment Type</th>
                                <th>Appointment Date</th>
                                <th>Status</th>
                                <th>Notes</th>
                                <th>Phone 1</th>
                                <th>Phone 2</th>
                                <th>Email</th>
                                <th>Address</th>
                                <th>City</th>
                                <th>State</th>
                                <th>Zip Code</th>      
                                <th>Created Date (EST)</th>
                                <th>Created By</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                appointmentReport.map(apptRecord => {
                                    return <React.Fragment>
                                            <tr style={{ fontFamily:'Arial, Helvetica, sans-serif', fontWeight:'500', fontSize:'11px', textTransform:'none', }}>
                                                <td>{apptRecord.Lead_Name}</td>
                                                <td>{apptRecord.Appointment_Type}</td>
                                                <td>{moment(apptRecord.Appointment_Date).format('MM/DD/YYYY LT')}</td>
                                                <td>{apptRecord.Status}</td>
                                                <td>{apptRecord.AppointmentNotes}</td>
                                                <td>
                                                    <NumberFormat
                                                        style={apptRecord.Phone_Number_1 == null || apptRecord.Phone_Number_1.toString().length > 10? { border:'none' } : { display: 'none'}}
                                                        mask=""
                                                        value={apptRecord.Phone_Number_1}
                                                        format="#-(###) ###-#####"
                                                        readonly="true"
                                                    />
                                                </td>
                                                <td>
                                                    <NumberFormat
                                                        style={apptRecord.Phone_Number_2 == null || apptRecord.Phone_Number_2.toString().length > 10? { border:'none' } : { display: 'none'}}
                                                        mask=""
                                                        value={apptRecord.Phone_Number_2}
                                                        format="#-(###) ###-#####"
                                                        readonly="true"
                                                    />
                                                </td>
                                                <td>{apptRecord.Email_Address}</td>
                                                <td>{apptRecord.Street_Address}</td>
                                                <td>{apptRecord.City}</td>
                                                <td>{apptRecord.State_Name}</td>
                                                <td>{apptRecord.Zipcode}</td>      
                                                <td>{moment(apptRecord.Created_Date).format('MM/DD/YYYY LT')}</td>
                                                <td>{apptRecord.Created_By}</td>
                                            </tr>
                                        </React.Fragment>
                                })
                            }
                        </tbody>
                    </table>
                </div> 
            </React.Fragment> 
            :
            <div class="text-center" style={{width:"100%", height:"100%", }}>
                <div class="spinner-border" role="status" style={{ marginTop:"100px", width:"5rem", height:"5rem",}}>
                    <span class="sr-only">Loading...</span>
                </div>
            </div>   
            }           
        </React.Fragment>
    )
}
