import React from 'react';
import PageTitle from '../components/PageTitle';

export default function Logout() {
    return (
        <React.Fragment>  
            <PageTitle>
                <div>
                    Logout
                    <div className="page-title-subheading">
                        This is the Logout page.
                    </div>
                </div>
            </PageTitle>
            <div className="row">
                <div className="col-lg-12">
                    <div className="main-card mb-3 card">
                        <div className="card-body">
                            <h5 className="card-title">Logout</h5>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
