import React from 'react';
import AppointmentSearch from '../components/AppointmentSearch';
import PageTitle from '../components/PageTitle';

export default function Leads() {    
    return (
        <React.Fragment>     
            <PageTitle>
                <div>
                    <div className="page-title">
                        Appointments                       
                    </div>
                </div>
            </PageTitle>            
            <AppointmentSearch />               
        </React.Fragment>
    )
}
