import React, { useMemo, useState, } from "react";
import axios from 'axios'
import { useTable, usePagination, useSortBy, useGlobalFilter, useAsyncDebounce, useBlockLayout, useResizeColumns } from "react-table";
import { Link } from 'react-router-dom';
import '../index.css';
import '../table.css'
import { MDBTable, MDBTableBody, MDBTableHead, MDBTableFoot } from 'mdbreact';
import Moment from 'react-moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"
import NumberFormat from "react-number-format";

function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,       
}) {
    const count = preGlobalFilteredRows.length    
    const [value, setValue] = React.useState(globalFilter)
    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined)
    }, 200)    
    
    return (         
        <span>
            
            Search:{' '}
            <input
                className="form-control"
                value={value || ""}
                onChange={e => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
                placeholder={`${count} records...`}
            />
        </span>
    )
}

function App() {
    const [pending, setPending] = useState(true); 
    const [show, setShow] = useState(false);
    const [noShow, setNoShow] = useState(false);
    const [walkout, setWalkout] = useState(false); 
    const [canceled, setCanceled] = useState(false);
    const [rescheduled, setRescheduled] = useState(false);
    const [isLoading, setIsLoading] = useState(false); 
    const today = new Date();
    const searchStartDate = addDays(today, -10);
    const searchEndDate = addDays(today, 60);
    const [startDate, setStartDate] = useState(new Date(searchStartDate.getFullYear(), searchStartDate.getMonth(), searchStartDate.getDate()));
    const [endDate, setEndDate] = useState(new Date(searchEndDate.getFullYear(), searchEndDate.getMonth(), searchEndDate.getDate()));
    const [appointments, setAppointments] = useState([]);        
    const access_token = localStorage.getItem("ApiToken");

    function addDays(date, days) {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }

    function formatDate(date) {
        return date !== null? <Moment format='MM/DD/YYYY hh:mm A' date={date}></Moment> : ''
    }
    
    function getReport() {      
        if (access_token) {
            setIsLoading(true);  
            axios({
                method: 'POST',
                url: `${process.env.REACT_APP_API_URL}/api/getappointments`,                
                headers: {                     
                    Authorization: `Bearer ${access_token}`
                },   
                data: {
                    "AppointmentStartDate": startDate, 
                    "AppointmentEndDate": endDate,
                    "pending": pending,
                    "show": show,
                    "noShow": noShow,
                    "walkout": walkout,
                    "canceled": canceled,
                    "rescheduled": rescheduled,
                  }                
            }).then(response => {
                let arrAppointments = response.data;               
                setAppointments(arrAppointments);  
                setIsLoading(false);                              
            }).catch(error => {
                console.log(error);
                setIsLoading(false); 
            })
        }    
    }    

    const columns = useMemo(
        () => [
            {
                Header: "Appointments",
                columns: [
                    {
                        Header: "First Name",
                        accessor: "FirstName", 
                        Cell: ({ cell: { value }, row: { original } }) => 
                            <Link 
                                to={{
                                    pathname: `lead`,
                                    state: { leadId: original.LeadId },                                    
                                }}
                            >{value}</Link>                       
                    },
                    {
                        Header: "Last Name",
                        accessor: "LastName"
                    },
                    {
                        Header: "Appointment Type",
                        accessor: "AppointmentType"
                    },
                    {
                        Header: "Appointment Date",
                        accessor: Appointment => {
                            return formatDate(Appointment.AppointmentDate)
                        }
                    },                    
                    {
                        Header: "Status",
                        accessor: "AppointmentStatus",                        
                    },
                    {
                        Header: "Notes",
                        accessor: "AppointmentNotes",
                        maxWidth: 400,
                        minWidth: 140,
                        width: 200,
                    },
                    {
                        Header: "Primary Phone",
                        accessor: PhoneNumber1 => {
                            return <NumberFormat
                                style={PhoneNumber1.PhoneNumber1 == null || PhoneNumber1.PhoneNumber1.toString().length > 10? { border:'none' } : { display: 'none'}}
                                mask=""
                                value={PhoneNumber1.PhoneNumber1}
                                format="#-(###) ###-#####"
                                readonly="true"
                            />
                        },                        
                        width: 185,
                    },
                    {
                        Header: "Secondary Phone",
                        accessor: PhoneNumber2 => {
                            return PhoneNumber2.PhoneNumber2 != null? <NumberFormat
                                style={PhoneNumber2.PhoneNumber2 == null || PhoneNumber2.PhoneNumber2.toString().length > 10? { border:'none' } : { display: 'none'}}
                                mask=""
                                value={PhoneNumber2.PhoneNumber2}
                                format="#-(###) ###-####"
                                readonly="true"
                            /> : ''
                        },
                        width: 185,
                    },                    
                    {
                        Header: "Email Address",
                        accessor: "EmailAddress",
                        maxWidth: 400,
                        minWidth: 140,
                        width: 200,
                    },
                    {
                        Header: "Address",
                        accessor: "StreetAddress",
                        maxWidth: 400,
                        minWidth: 140,
                        width: 200,
                    },
                    {
                        Header: "City",
                        accessor: "City",
                        maxWidth: 400,
                        minWidth: 140,
                        width: 200,
                    },
                    {
                        Header: "State",
                        accessor: "StateName"
                    },
                    {
                        Header: "Zipcode",
                        accessor: "Zipcode"
                    },
                    {
                        Header: "Created By",
                        accessor: "CreatedBy"
                    },
                    {
                        Header: "Created Date (EST)",
                        accessor: CreatedDate => {
                            return formatDate(CreatedDate.CreatedDate) 
                        }
                    },                   
                ],
                Footer: "",
            },            
        ],
        []
    );       
    
    let data = appointments; 
    //const columns = React.useMemo(() => props.columns, [props.columns]);
    const defaultColumn = React.useMemo(
        () => ({
            minWidth: 30,
            width: 100,
            maxWidth: 400
        }),
        []
    );    
    
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        footerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize, },
        state,
        preGlobalFilteredRows,
        setGlobalFilter,
    } = useTable(
        {
            columns,
            data,
            initialState: { pageSize: 50 },
            defaultColumn
        },
        useGlobalFilter,
        useSortBy,
        usePagination,
        useBlockLayout,
        useResizeColumns
    );    
  
  // Render the UI for your table
  return (      
    <React.Fragment>
        <div>
            <p style={{ width:'180px', float:'left', fontSize:'11px', fontWeight:'500', textTransform:'none', }}>
                <strong>Start Date</strong>
                <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                />
            </p>
            <p style={{ width:'180px', float:'left', fontSize:'11px', fontWeight:'500', textTransform:'none',}}>
                <strong>End Date</strong>
                <DatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                />
            </p>
            <ul style={{ marginLeft:'52px', }}>
                <li style={{ display:'inline-block', marginRight:'10px' }}>
                    <input 
                        type="checkbox" 
                        id="chkPending" 
                        value={ pending }
                        defaultChecked={ pending }                      
                        onChange={e => { setPending(e.target.checked); }} 
                    /> Pending</li>
                <li style={{ display:'inline-block', marginRight:'10px' }}>
                    <input 
                        type="checkbox" 
                        id="chkShow" 
                        value={ show }
                        defaultChecked={ show } 
                        onChange={e => { setShow(e.target.checked); }}
                    /> Show</li>
                <li style={{ display:'inline-block', marginRight:'10px' }}>
                    <input 
                        type="checkbox" 
                        id="chkNoShow" 
                        value={ noShow }
                        defaultChecked={ noShow } 
                        onChange={e => { setNoShow(e.target.checked) }}
                    /> No Show</li>                
                <li style={{ display:'inline-block', marginRight:'10px' }}>
                    <input 
                        type="checkbox" 
                        id="chkWalkout"
                        value={ walkout }
                        defaultChecked={ walkout } 
                        onChange={e => { setWalkout(e.target.checked) }} 
                    /> Walkout</li>
                <li style={{ display:'inline-block', marginRight:'10px' }}>
                    <input 
                        type="checkbox" 
                        id="chkCanceled"
                        value={ canceled }
                        defaultChecked={ canceled } 
                        onChange={e => { setCanceled(e.target.checked) }} 
                    /> Canceled</li>
                <li style={{ display:'inline-block', marginRight:'10px' }}>
                    <input 
                        type="checkbox" 
                        id="chkRescheduled"
                        value={ rescheduled }
                        defaultChecked={ rescheduled } 
                        onChange={e => { setRescheduled(e.target.checked) }} 
                    /> Rescheduled</li>               
            </ul>
            <p style={{ width:'100px', }}>
                <button type={'button'} onClick={ getReport }>
                    Submit    
                </button>
            </p>    
        </div>
        { !isLoading? <React.Fragment>
            <MDBTable id="searchtable" 
                responsiveSm 
                responsiveMd 
                mdbtablescroll="true" 
                
                className="table table-striped text-center mb-5" 
                bordered={true}

                {...getTableProps()}
                border={1}           
            >   
                <MDBTableHead >
                    <tr>
                        <th
                        colSpan={100}
                        style={{
                            textAlign: 'left',
                            padding: 10,
                            background: '#669999'
                        }}
                        >
                            <GlobalFilter
                                preGlobalFilteredRows={preGlobalFilteredRows}
                                globalFilter={state.globalFilter}
                                setGlobalFilter={setGlobalFilter}
                                filteredCallData={state.filteredCallData}
                            />
                        </th>
                    </tr>
                    {headerGroups.map((group) => (
                    <tr {...group.getHeaderGroupProps()}>
                    {group.headers.map((column) => (
                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>{column.render("Header")}</th>
                    ))}
                    </tr>
                    ))}
                </MDBTableHead>
                <MDBTableBody {...getTableBodyProps()}>
                {page.map((row, i) => {
                    prepareRow(row);

                    return (
                    <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                        return (
                            <td style={{textAlign:"left"}} {...cell.getCellProps()}>{cell.render("Cell")}</td>
                        );
                        })}
                    </tr>
                    );
                })}
                </MDBTableBody>
                <MDBTableFoot>
                {footerGroups.map((group) => (
                    <tr {...group.getFooterGroupProps()}>
                    {group.headers.map((column) => (
                        <td {...column.getFooterProps()}>{column.render("Footer")}</td>
                    ))}
                    </tr>
                ))}
                </MDBTableFoot>
            </MDBTable>
            </React.Fragment>
            :
            <div class="text-center" style={{width:"100%", height:"100%", }}>
                <div class="spinner-border" role="status" style={{ marginTop:"100px", width:"5rem", height:"5rem",}}>
                    <span class="sr-only">Loading...</span>
                </div>
            </div>   
        }
        <div className="pagination">
            <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                {"<<"}
            </button>{" "}
            <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                {"<"}
            </button>
            {" "}
            <button onClick={() => nextPage()} disabled={!canNextPage}>
                {">"}
            </button>
            {" "}
            <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                {">>"}
            </button>
            {" "}
            <span>
                Page{" "}
                <strong>
                    {pageIndex + 1} of {pageCount}
                </strong>{" "}
            </span>
            <span>
                | Go to page:{" "}
                <input
                    type="number"
                    defaultValue={pageIndex + 1}
                    onChange={(e) => {
                    const page = e.target.value ? Number(e.target.value) - 1 : 0;
                    gotoPage(page);
                    }}
                    style={{ width: "100px" }}
                />
            </span>
            {" "}
            <select
                value={pageSize}
                onChange={(e) => {
                    setPageSize(Number(e.target.value));
                }}
            >
                {[10, 50, 100, 500].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                </option>
                ))}
            </select>
      </div>
    </React.Fragment>
  );
}

export default App;
