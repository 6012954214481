import React, { Fragment, useState, useEffect } from 'react'
import { Link, Redirect, useLocation } from 'react-router-dom'
import { Formik, } from "formik";
import Form from "react-bootstrap/Form";
import * as Yup from 'yup';
import axios from 'axios'
import Alert from 'react-bootstrap/Alert';
import moment from 'moment';
import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";

export default function LeadPhoneCallSave() {    
   
    const location = useLocation();
    const leadId = location.state.leadId || 0 ; 
    const appointmentId = location.state.appointmentId || 0; 
    const access_token = localStorage.getItem("ApiToken");
    const [isLoading, setIsLoading] = useState(false);
    const [appointment, setAppointment] = useState([]);
    const [goToLeadAppointments, setGoToLeadAppointments] = useState(false);
    const [appointmentStatuses, setAppointmentStatuses] = useState([]);
    const [appointmentTypes, setAppointmentTypes] = useState([]);
    
    const FormSchema = Yup.object().shape({        
        appointmentDate: Yup.date()
            //.min(new Date(), "You can't schedule an appointment in the past.")
            .required('Appointment Date Is Required'),
        appointmentTypeId: Yup.number() 
            .min(3)      
            .required("Appointment Type is required"),
        appointmentStatusId: Yup.number()
            .min(1)      
            .required("Appointment Status is required"),
    });
        
    useEffect(() => {
        const access_token = localStorage.getItem("ApiToken");              
        if (access_token) {
            setIsLoading(true);
            axios({
                method: 'GET',
                url: `${process.env.REACT_APP_API_URL}/api/getappointmentstatus`,                
                headers: {                     
                    Authorization: `Bearer ${access_token}`
                }, 
            }).then(response => { 
                let arrResults = response.data;
                
                if (arrResults !== null && arrResults.length > 0) {
                    setAppointmentStatuses(arrResults);
                }
                setIsLoading(false);
            }).catch(error => {
                console.log(error);
                setIsLoading(false);
            })             
        }        
    }, [access_token])  
    
    useEffect(() => {        
        if (access_token) {
            setIsLoading(true);
            axios({
                method: 'GET',
                url: `${process.env.REACT_APP_API_URL}/api/getappointmenttypes`,                
                headers: {                     
                    Authorization: `Bearer ${access_token}`
                }, 
            }).then(response => { 
                let arrResults = response.data;
                
                if (arrResults !== null && arrResults.length > 0) {
                    setAppointmentTypes(arrResults);
                }
                setIsLoading(false);
            }).catch(error => {
                console.log(error);
                setIsLoading(false);
            })             
        }        
    }, [access_token])   
    
    useEffect(() => {       
        if (access_token) {
            setIsLoading(true); 
            axios({
                method: 'POST',
                url: `${process.env.REACT_APP_API_URL}/api/getappointmentbyappointmentid`,  
                data: {
                    "AppointmentId": appointmentId,
                },              
                headers: {                     
                    Authorization: `Bearer ${access_token}`
                }, 
            }).then(response => { 
                let arrResults = response.data;
                
                if (arrResults !== null && arrResults.length > 0) {
                    setAppointment(arrResults[0]);
                }          
                setIsLoading(false);        
            }).catch(error => {                     
                console.log(error);
                setIsLoading(false);
            })             
        } 
         
    }, [access_token, appointmentId])    
    
    function handleFormSubmission(values, actions) {  
        const access_token = localStorage.getItem("ApiToken");  
        const systemUserId = localStorage.getItem("SystemUserId");              
        if (access_token) {
            setIsLoading(true);
            axios({
                method: 'POST',
                url: `${process.env.REACT_APP_API_URL}/api/saveappointment`,                
                headers: {                     
                    Authorization: `Bearer ${access_token}`
                },  
                data: {
                    "AppointmentId": values.appointmentId,
                    "AppointmentTypeId": values.appointmentTypeId,
                    "LeadId": values.leadId,
                    "AppointmentDate": moment(values.appointmentDate).format('MM/DD/yyyy LT'),
                    "AppointmentStatusId": values.appointmentStatusId,
                    "CreatedBySystemUserId": systemUserId,
                    "IsDeleted": values.isDeleted,
                    "AppointmentNotes": values.appointmentNotes,
                  }
            }).then(response => { 
                setGoToLeadAppointments(true);
                setIsLoading(false);
            }).catch(error => {
                console.log(error);
                setIsLoading(false);
            }) 
        }
    }
    console.log('appointmentId - ' + appointmentId)
    return (        
        !isLoading?         
        <React.Fragment>             
            {goToLeadAppointments && <Redirect to={{
                                    pathname: `lead`,
                                    state: { leadId: leadId },                                    
                                }} />}                                        
            <p className="lead mb-5" style={{ padding:20 }}>Lead Appointment</p>                
                <Formik enableReinitialize={true}                 
                    initialValues={{
                            appointmentId: appointmentId,
                            leadId: leadId,
                            appointmentDate: appointment.AppointmentDate != null? moment(appointment.AppointmentDate).format('MM/DD/yyyy LT') : '',
                            appointmentStatusId: appointment.AppointmentStatusId != null? appointment.AppointmentStatusId : 1, // Set default to pending
                            appointmentStatus: appointment.Status != null? appointment.Status : '',                            
                            appointmentTypeId: appointment.AppointmentTypeId != null? appointment.AppointmentTypeId : 0,
                            appointmentType: appointment.AppointmentType != null? appointment.AppointmentType : '',                            
                            createdDate: appointment.CreatedDate != null? moment(appointment.CreatedDate).format('MM/DD/yyyy LT') : null,
                            createdBySystemUserId: appointment.CreatedBySystemUserId != null? appointment.CreatedBySystemUserId : 1,
                            isDeleted: appointment.IsDeleted != null? appointment.IsDeleted : false,
                            appointmentNotes: appointment.AppointmentNotes != null? appointment.AppointmentNotes : '',
                          }}     
                                 
                    validationSchema={FormSchema}
                    onSubmit={(values, actions) => {
                        // same shape as initial values
                        handleFormSubmission(values, actions);
                    }}
                    
                >
                    {({ 
                        isSubmitting,
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        values,
                        touched,
                        errors,
                        setFieldValue,
                }) => (                    
                    <Form 
                        autoComplete="off"
                        onSubmit={handleSubmit}>                           
                        <div className="form_container" style={{ padding:20 }}>                            
                            <div className="col-12 text-center mb-3">
                                <label htmlFor="appointmentType" className="semibold-font"><span style={{ color: "#ff0000" }}>*</span> Appointment Type</label>
                                <br />                                
                                <select
                                    id="appointmentTypeId"
                                    name="appointmentTypeId"
                                    value={values.appointmentTypeId}
                                    onChange={handleChange}
                                    onBlur={handleBlur} 
                                    isInvalid={errors.appointmentTypeId && touched.appointmentTypeId}
                                    autoComplete="appointmentTypeId"
                                    disabled={appointmentId === 0? "" : "true"} 
                                                                        >                                    
                                    {appointmentTypes.map((apptTypes) => (
                                    <option key={apptTypes.AppointmentTypeId} value={apptTypes.AppointmentTypeId}>
                                        {apptTypes.AppointmentType}
                                    </option>
                                    ))}
                                </select>
                                {
                                    errors.appointmentTypeId &&
                                    touched.appointmentTypeId &&
                                    <div className="input-feedback" class="text-danger">
                                        Appointment Type is required 
                                    </div>
                                }   
                            </div>              
                            <div className="col-12 text-center mb-3">
                                <label htmlFor="appointmentStatus" className="semibold-font"><span style={{ color: "#ff0000" }}>*</span> Appointment Status</label>
                                <br />
                                <select
                                    id="appointmentStatusId"
                                    name="appointmentStatusId"
                                    value={values.appointmentStatusId}                                    
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={errors.appointmentStatus && touched.appointmentStatus}
                                    autoComplete="appointmentStatusId"
                                >
                                    <option value="" label="--- Select a Appointment Status ---" />
                                    {appointmentStatuses.map((apptStatus) => (
                                    <option key={apptStatus.AppointmentStatusId} value={apptStatus.AppointmentStatusId} label={apptStatus.Status}>                                        
                                    </option>
                                    ))}
                                </select>
                                {
                                    errors.appointmentStatusId &&
                                    touched.appointmentStatusId &&
                                    <div className="input-feedback" class="text-danger">
                                        Appointment Status is required 
                                    </div>
                                }                                
                            </div>    
                            <div className="form-group">
                                <label htmlFor="appointmentDate" className="semibold-font"><span style={{ color: "#ff0000" }}>*</span> Appointment Date</label>
                                <Datetime
                                    id="appointmentDate" 
                                    name="appointmentDate" 
                                    value={values.appointmentDate} 
                                    onChange={(dateFromValue) => {setFieldValue('appointmentDate', dateFromValue)}}
                                    onBlur={(dateFromValue) => {setFieldValue("appointmentDate" , dateFromValue)}} 
                                    isInvalid={errors.appointmentDate && touched.appointmentDate}                                                                       
                                />  
                                {
                                    errors.appointmentDate &&
                                    touched.appointmentDate &&
                                    <div className="input-feedback" class="text-danger">
                                        {errors.appointmentDate}
                                    </div>
                                }                               
                            </div> 
                            <div className="form-group">
                                <label htmlFor="notes" className="semibold-font"><span style={{ color: "#ff0000" }}></span> Notes</label>
                                <Form.Control 
                                    type="text" 
                                    id="appointmentNotes" 
                                    name="appointmentNotes" 
                                    placeholder="Notes" 
                                    value={values.appointmentNotes}
                                    onChange={handleChange}
                                    onBlur={handleBlur} 
                                    isInvalid={errors.appointmentNotes && touched.appointmentNotes}
                                    autoComplete="appointmentNotes"
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.appointmentNotes}
                                </Form.Control.Feedback>
                            </div>  
                            <div className="row mt-5">
                                <div className="col-12 text-center mb-3">
                                    <button type="submit" className="btn btn-primary">
                                    {isSubmitting ? (
                                        <Fragment>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Please Wait...
                                        </Fragment>
                                    ) : 'Save Appointment'}
                                    </button>
                                </div>
                                <div className="col-12 text-center my-3">
                                    <Link to={{
                                        pathname: `lead`,
                                        state: { leadId: leadId },                                    
                                    }}><strong>Cancel</strong></Link>
                                </div>                                                
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
            <Alert 
                variant="danger" 
                onClose={() => this.closeAlert()} 
                dismissible
                show={false}
            >
                { "" }
            </Alert> 
        </React.Fragment> 
        : 
        <div class="text-center" style={{width:"100%", height:"100%", }}>
            <div class="spinner-border" role="status" style={{ marginTop:"100px", width:"5rem", height:"5rem",}}>
                <span class="sr-only">Loading...</span>
            </div>
        </div>       
    )
}
