import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import MainLayout from './layouts/MainLayout';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import './assets/main.css';
import About from './pages/About';
import AppointmentReport from './pages/AppointmentReport';
import Appointments from './pages/Appointments';
import AppointmentSave from './pages/AppointmentSave';
import Campaign from './pages/Campaign';
import CampignReport from './pages/CampaignReport';
import Campaigns from './pages/Campaigns';
import CampaignSave from './pages/CampaignSave';
import ChangePassword from './pages/ChangePassword';
import ForgotPassword from './pages/ForgotPassword';
import Home from './pages/Home';
import Lead from './pages/Lead';
import LeadAppointmentSave from './pages/LeadAppointmentSave';
import LeadCampaignSave from './pages/LeadCampaignSave';
import Leads from './pages/Leads';
import LeadSave from './pages/LeadSave';
import Login from './pages/Login';
import Logout from './pages/Logout';
import LeadNoteSave from './pages/LeadNoteSave';
import LeadPhoneCallSave from './pages/LeadPhoneCallSave'
import NotFound from './pages/NotFound';
import PhoneCalls from './pages/PhoneCalls';
import PhoneCallSave from './pages/PhoneCallSave';
import Reports from './pages/Reports';
import Settings from './pages/Settings';
import User from './pages/User';
import UserDetails from './pages/UserDetails'
import Users from './pages/Users';
import UserSave from './pages/UserSave';

class App extends Component {
  render() {    
    return (
      <Router>
        <Switch>
          <Route exact path="/login" render={routeProps => <Login {...routeProps} /> } />
          <Route exact path="/logout" render={routeProps => <Logout {...routeProps} /> } />
          <Route exact path="/" render={routeProps => (
            
            <Login {...routeProps} />
            
            )} />
          <Route exact path="/about" render={routeProps => (
            <MainLayout>
              <About {...routeProps} />
            </MainLayout>
          )} />             
          <Route exact path="/appointment-report" render={routeProps => (
            <MainLayout>
              <AppointmentReport {...routeProps} />
            </MainLayout>
          )} /> 
          <Route exact path="/appointments" render={routeProps => (
            <MainLayout>
              <Appointments {...routeProps} />
            </MainLayout>
          )} />  
          <Route exact path="/appointmentsave" render={routeProps => (
            <MainLayout>
              <AppointmentSave {...routeProps} />
            </MainLayout>
          )} />         
           <Route exact path="/campaign" render={routeProps => (
            <MainLayout>
              <Campaign {...routeProps} />
            </MainLayout>
          )} />
          <Route exact path="/campaign-report" render={routeProps => (
            <MainLayout>
              <CampignReport {...routeProps} />
            </MainLayout>
          )} />
          <Route exact path="/campaigns" render={routeProps => (
            <MainLayout>
              <Campaigns {...routeProps} />
            </MainLayout>
          )} />
          <Route exact path="/campaignsave" render={routeProps => (
            <MainLayout>
              <CampaignSave {...routeProps} />
            </MainLayout>
          )} />   
          <Route exact path="/forgot-password" render={routeProps => (
            <ForgotPassword {...routeProps} />            
          )} />  
          <Route exact path="/leadappointmentsave" render={routeProps => (
            <MainLayout>
              <LeadAppointmentSave {...routeProps} />
            </MainLayout>
          )} />   
          <Route exact path="/leadcampaignsave" render={routeProps => (
            <MainLayout>
              <LeadCampaignSave {...routeProps} />
            </MainLayout>
          )} />     
          <Route exact path="/change-password" render={routeProps => (
            <ChangePassword {...routeProps} />            
          )} />
          <Route exact path="/home" render={routeProps => (
            <MainLayout>
              <Home {...routeProps} />
            </MainLayout>
          )} />
          <Route exact path="/lead" render={routeProps => (
            <MainLayout>
              <Lead {...routeProps} />
            </MainLayout>
          )} />
          <Route exact path="/leadnotesave" render={routeProps => (
            <MainLayout>
              <LeadNoteSave {...routeProps} />
            </MainLayout>
          )} />          
          <Route exact path="/leadphonecallsave" render={routeProps => (
            <MainLayout>
              <LeadPhoneCallSave {...routeProps} />
            </MainLayout>
          )} />          
          <Route exact path="/leads" render={routeProps => (
            <MainLayout>
              <Leads {...routeProps} />
            </MainLayout>
          )} />
          <Route exact path="/leadsave" render={routeProps => (
            <MainLayout>
              <LeadSave {...routeProps} />
            </MainLayout>
          )} />
          <Route exact path="/passwordreset" render={routeProps => (
            <ForgotPassword {...routeProps} />            
          )} />
          <Route exact path="/phonecalls" render={routeProps => (
            <MainLayout>
              <PhoneCalls {...routeProps} />
            </MainLayout>
          )} />
          <Route exact path="/phonecallsave" render={routeProps => (
            <MainLayout>
              <PhoneCallSave {...routeProps} />
            </MainLayout>
          )} />
          <Route exact path="/reports" render={routeProps => (
            <MainLayout>
              <Reports {...routeProps} />
            </MainLayout>
          )} />
          <Route exact path="/settings" render={routeProps => (
            <MainLayout>
              <Settings {...routeProps} />
            </MainLayout>
          )} />
          <Route exact path="/user" render={routeProps => (
            <MainLayout>
              <User {...routeProps} />
            </MainLayout>
          )} />          
          <Route exact path="/userdetail" render={routeProps => (
            <MainLayout>
              <UserDetails {...routeProps} />
            </MainLayout>
          )} />   
          <Route exact path="/users" render={routeProps => (
            <MainLayout>
              <Users {...routeProps} />
            </MainLayout>
          )} />
          <Route exact path="/usersave" render={routeProps => (
            <MainLayout>
              <UserSave {...routeProps} />
            </MainLayout>
          )} />          
          <Route render={routeProps => (
            <MainLayout>
              <NotFound />
            </MainLayout>
          )} />
        </Switch>
      </Router>
    );
  }
}

export default App;
