import React, { useMemo, useState, useEffect } from "react";
import axios from 'axios'
import { useTable, usePagination, useSortBy, useGlobalFilter, useAsyncDebounce } from "react-table";
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import '../index.css';
import '../table.css'
import { MDBTable, MDBTableBody, MDBTableHead, MDBTableFoot } from 'mdbreact';

function UserTable({ columns, data }) {    
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        footerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
        state,
        preGlobalFilteredRows,
        setGlobalFilter,
    } = useTable(
        {
            columns,
            data,
            initialState: { pageSize: 10 }
        },
        useGlobalFilter,
        useSortBy,
        usePagination,
    );


  // Render the UI for your table
  return (
    <React.Fragment>
        <MDBTable id="searchtable" 
            responsiveSm 
            responsiveMd 
            mdbtablescroll="true" 
            
            className="table table-striped text-center mb-5" 
            bordered={true}

            {...getTableProps()}
            border={1}
           
        >   
            <MDBTableHead >
                <tr>
                    <th
                        colSpan={100}
                        style={{
                            textAlign: 'left',
                            padding: 10,
                            background: '#669999'
                        }}
                    >
                        <GlobalFilter
                            preGlobalFilteredRows={preGlobalFilteredRows}
                            globalFilter={state.globalFilter}
                            setGlobalFilter={setGlobalFilter}
                        />
                    </th>
                </tr>
                {headerGroups.map((group) => (
                <tr {...group.getHeaderGroupProps()}>
                {group.headers.map((column) => (
                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>{column.render("Header")}</th>
                ))}
                </tr>
                ))}
            </MDBTableHead>
            <MDBTableBody {...getTableBodyProps()}>
            {page.map((row, i) => {
                prepareRow(row);

                return (
                <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                    return (
                        <td style={{textAlign:"left"}} {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                    })}
                </tr>
                );
            })}
            </MDBTableBody>
            <MDBTableFoot>
            {footerGroups.map((group) => (
                <tr {...group.getFooterGroupProps()}>
                {group.headers.map((column) => (
                    <td {...column.getFooterProps()}>{column.render("Footer")}</td>
                ))}
                </tr>
            ))}
            </MDBTableFoot>
        </MDBTable>
        <div className="pagination">
            <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                {"<<"}
            </button>{" "}
            <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                {"<"}
            </button>
            {" "}
            <button onClick={() => nextPage()} disabled={!canNextPage}>
                {">"}
            </button>
            {" "}
            <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                {">>"}
            </button>
            {" "}
            <span>
                Page{" "}
                <strong>
                    {pageIndex + 1} of {pageCount}
                </strong>{" "}
            </span>
            <span>
                | Go to page:{" "}
                <input
                    type="number"
                    defaultValue={pageIndex + 1}
                    onChange={(e) => {
                    const page = e.target.value ? Number(e.target.value) - 1 : 0;
                    gotoPage(page);
                    }}
                    style={{ width: "100px" }}
                />
            </span>
            {" "}
            <select
                value={pageSize}
                onChange={(e) => {
                    setPageSize(Number(e.target.value));
                }}
            >
                {[10, 50, 100, 500].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                </option>
                ))}
            </select>
      </div>
    </React.Fragment>
  );
}

function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
}) {
    const count = preGlobalFilteredRows.length
    const [value, setValue] = React.useState(globalFilter)
    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined)
    }, 200)

    return (
        <span>
            Search:{' '}
            <input
                className="form-control"
                value={value || ""}
                onChange={e => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
                placeholder={`${count} records...`}
            />
        </span>
    )
}


function App() {
    function formatDate(date) {
        return date !== null? <Moment format='MM/DD/YYYY hh:mm:ss A' date={date}></Moment> : ''
    }

    const [users, setUsers] = useState([]);        
    const access_token = localStorage.getItem("ApiToken");
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (access_token) {
            setIsLoading(true);
            axios({
                method: 'GET',
                url: `${process.env.REACT_APP_API_URL}/api/getsystemusers`,                
                headers: {                     
                    Authorization: `Bearer ${access_token}`
                },                
            }).then(response => {
                let arrUsers = Object.values(response.data);
                setUsers(arrUsers);
                setIsLoading(false);
            }).catch(error => {
                console.log(error);
                setIsLoading(false);
            })            
        }
    }, [access_token])
    const columns = useMemo(
        () => [
            {
                Header: "Users",
                columns: [
                    {
                        Header: "First Name",
                        accessor: "FirstName", 
                        Cell: ({ cell: { value }, row: { original } }) => 
                            <Link 
                                to={{
                                    pathname: `user/`,
                                    state: { systemUserId: original.SystemUserId },                                    
                                }}
                            >{value}</Link>                       
                    },
                    {
                        Header: "Last Name",
                        accessor: "LastName",
                    },
                    {
                        Header: "Email Address",
                        accessor: "EmailAddress"
                    },
                    {
                        Header: "Login Id",
                        accessor: "LoginId"
                    },
                    {
                        Header: "Role",
                        accessor: "Role"
                    },
                    {
                        Header: "End Date (EST)",
                        accessor: User => {
                            return formatDate(User.EndDate)
                        }
                    },
                    {
                        Header: "Created Date (EST)",
                        accessor: User => {
                            return formatDate(User.CreatedDate)
                        }
                    },
                ],
                Footer: "",
            },
            
        ],
        []
    );

    const data = users;
  
    return !isLoading? <UserTable columns={columns} data={data} /> 
    :
    <div class="text-center" style={{width:"100%", height:"100%", }}>
        <div class="spinner-border" role="status" style={{ marginTop:"100px", width:"5rem", height:"5rem",}}>
            <span class="sr-only">Loading...</span>
        </div>
    </div>;
}

export default App;
