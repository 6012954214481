import React from 'react';
import PageTitle from '../components/PageTitle';
import { NavLink, } from 'react-router-dom';

export default function Reports() {    
    return (
        <React.Fragment>  
            <PageTitle>
                <div>
                    Reports
                    <div className="page-title-subheading">
                        This is the Reports page.
                    </div>
                </div>
            </PageTitle>
            <div className="row">
                <div className="col-lg-12">
                    <div className="main-card mb-3 card">
                        <div className="card-body">
                            <ul className="vertical-nav-menu">
                                <li>
                                    <NavLink exact to='./appointment-report' activeClassName="mm-active">
                                        <i className='metismenu-icon pe-7s-rocket'></i>
                                        Attendance Report
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink exact to='./campaign-report' activeClassName="mm-active">
                                        <i className='metismenu-icon pe-7s-rocket'></i>
                                        Campaign Report
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
