import React  from 'react';
import UserTable from './UserTable';
import '../index.css';
import '../table.css'

function UserSearch () {        
       
    return (
        <React.Fragment>
            <UserTable></UserTable>
        </React.Fragment>
    );
}

export default UserSearch;