import React, { Component, Fragment } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { Formik } from 'formik';
import Form from "react-bootstrap/Form";
import * as Yup from 'yup';
import axios from 'axios'
import Alert from 'react-bootstrap/Alert';
import CampaignLogo from '../assets/images/login-logo.png';

export default class Login extends Component {    
    state = {
        alertMessage: '',
        alertVisible: false,
        goToDashboard: false,
        goToResetPassword: false,
        goToChangePassword: false,
    }

    handleFormSubmission = (values, actions) => {
        // Make a post request
        const { email, password } = values;
        axios({
            url: `${process.env.REACT_APP_API_URL}/api/Login`,            
            method: 'POST',
            data: {
                Username: email,
                Password: password,
            },
            headers: { 'Content-Type': 'application/json' },   
        }).then(response => {
            let obj = JSON.parse(JSON.stringify(response.data));                
            console.log(obj);

            this.setState({                    
                loading: false,
            })  

            const feedback = response.data;
            if (feedback.hasOwnProperty("TemporaryPasswordId")) {
            //if (obj.TemporaryPasswordId > 0) {
                // This is temporary password redirect to temporary password page
                this.setState({
                    alertMessage: '',
                    alertVisible: false,
                    goToDashboard: false,
                    goToResetPassword: false,
                    goToChangePassword: true,
                })
                console.log('goToResetPassword = ' + this.goToResetPassword)
            }
            // Store APIToken in LocalStorage
            if (obj.Token !== null && obj.Token.length > 0)
            {
                localStorage.setItem("ApiToken", obj.Token);
                localStorage.setItem("SystemUserId", obj.SystemUserId);
                localStorage.setItem("RoleId", obj.RoleId)
                localStorage.setItem("TemporaryPasswordId", obj.TemporaryPasswordId) 
                             
            }
            
            
                        
            if (feedback.hasOwnProperty("Code")) {
                if (feedback.Code === 'success') {                                        
                    // This is a success redirect to dashboard
                    this.setState({
                        alertMessage: '',
                        alertVisible: false,
                        goToDashboard: true,
                        goToResetPassword: false,
                        goToChangePassword: false,
                    })
                } else {
                    // Display Error Message
                    this.setState({
                        alertMessage: `Invalid Login Details`,
                        alertVisible: true,
                        goToDashboard: false,
                        goToResetPassword: false,
                        goToChangePassword: false,
                    })
                }
            } 
            actions.setSubmitting(false)
        }).catch(error => {
            // Display Error Message
            this.setState({
                alertMessage: `Invalid Login Details`,
                alertVisible: true,
                goToDashboard: false,
                goToResetPassword: false,
                goToChangePassword: false,
            })
            actions.setSubmitting(false)
        })
    }

    closeAlert = () => {
        this.setState({
            alertVisible: false
        })
    }

    render() {
        const LoginSchema = Yup.object().shape({
            password: Yup.string()
              .required('Password Is Required'),
            email: Yup.string()
              .email('Invalid Email Address')
              .required('Email Address Is Required')
        });

        return (
            <React.Fragment>
                {this.state.goToDashboard && <Redirect to="/home" />}
                {this.state.goToChangePassword && <Redirect to={{
                    pathname: "/change-password",
                    search: "?invalid_login=yes",
                    state: { 
                        message: 'Please Create A New Password Using Your Temporary Password', 
                        invalid_login: true 
                    }
                }} />}
                <div className="content py-5">
                    <div className="container">
                        <div className="referral_form">
                            <div className="row justify-content-between">
                                <div>
                                    <h2 className="bold-font">Campaign Manager</h2>
                                    <img src={ CampaignLogo } height="200" width="300" alt=""></img>
                                </div>
                                <div className="col-12 col-md-6 mb-3 order-2 order-md-1">
                                    <div className="pr-0 pr-md-5">
                                        <p className="lead mb-5">Please login to your account.</p>
                                        <Formik
                                            initialValues={{
                                                email: '',
                                                password: '',
                                            }}
                                            validationSchema={LoginSchema}
                                            onSubmit={(values, actions) => {
                                                // same shape as initial values
                                                this.handleFormSubmission(values, actions);
                                            }}
                                        >
                                            {({ 
                                                isSubmitting,
                                                handleSubmit,
                                                handleChange,
                                                handleBlur,
                                                values,
                                                touched,
                                                errors,
                                            }) => (
                                                <Form noValidate onSubmit={handleSubmit}>
                                                    <div className="form_container">
                                                        <div className="form-group">
                                                            <label htmlFor="email" className="semibold-font">Email Address</label>
                                                            <Form.Control 
                                                                type="text" 
                                                                id="email" 
                                                                name="email" 
                                                                placeholder="Your Email Address" 
                                                                value={values.email}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur} 
                                                                isInvalid={errors.email && touched.email}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors.email}
                                                            </Form.Control.Feedback>
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="password" className="semibold-font">Password</label>
                                                            <Form.Control 
                                                                type="password" 
                                                                id="password" 
                                                                name="password"
                                                                placeholder="Enter your password" 
                                                                value={values.password}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur} 
                                                                isInvalid={errors.password && touched.password}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors.password}
                                                            </Form.Control.Feedback>
                                                        </div>
                                                        <div className="row mt-5">
                                                            <div className="col-12 text-center mb-3">
                                                                <button type="submit" className="btn btn-primary">
                                                                {isSubmitting ? (
                                                                    <Fragment>
                                                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Please Wait...
                                                                    </Fragment>
                                                                ) : 'Log In'}
                                                                </button>
                                                            </div>
                                                            <div className="col-12 text-center my-3">
                                                                <Link to="/forgot-password"><strong>Forgot Password?</strong></Link>
                                                            </div>
                                                            <div className="col-12 text-center my-3 ">
                                                                <div className="divider">
                                                                    <div className="or">Or</div>
                                                                </div>
                                                                <Link to="/signup" className="btn-theme bg-orange btn-fixed-80 text-white py-3">Create Your Account</Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Form>
                                            )}
                                        </Formik>
                                        <Alert 
                                            variant="danger" 
                                            onClose={() => this.closeAlert()} 
                                            dismissible
                                            show={this.state.alertVisible}
                                        >
                                            {this.state.alertMessage}
                                        </Alert>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
