import React  from 'react';
import AppointmentTable from './AppointmentTable';
import '../index.css';
import '../table.css'

function LeadSearch () {        
       
    return (
        <React.Fragment>
            <AppointmentTable></AppointmentTable>
        </React.Fragment>
    );
}

export default LeadSearch;