import React, { Component, Fragment } from 'react'
import { Formik } from 'formik';
import axios from 'axios';
import Form from "react-bootstrap/Form";
import * as Yup from 'yup';
import Alert from 'react-bootstrap/Alert';

export default class ChangePassword extends Component {
    state = {
        alertMessage: '',
        alertVisible: false,
        alertVariant: ''
    }

    handleFormSubmission = (values, actions) => {
        // Make a post request
        const { email } = values;
        axios({
            url: `${process.env.REACT_APP_API_URL}/api/forgotpassword`,
            method: 'POST',
            data: {
                LoginId: email
            }
        }).then(response => {
            if (response.data.Code === 'success') {
                this.setState({
                    alertMessage: 'Please check Your email For instructions to reset your account password',
                    alertVisible: true,
                    alertVariant: 'success',
                })                
            } else {
                this.setState({
                    alertMessage: 'Error. Please try again later',
                    alertVisible: true,
                    alertVariant: 'danger',
                })
            }            
            actions.setSubmitting(false)
        }).catch(error => {
            this.setState({
                alertMessage: 'Error. Please Try Again Later',
                alertVisible: true,
                alertVariant: 'danger',
            })            
            actions.setSubmitting(false)
        })

    }

    closeAlert = () => {
        this.setState({
            alertVisible: false
        })
    }

    render() {
        const SignupSchema = Yup.object().shape({
            email: Yup.string()
                .email('Invalid Email Address')
                .required('Email Address Is Required')
        });
        return (            
            <div className="container"> 
                <div className="col-12 col-md-6 mb-3 order-2 order-md-1">                            
                    <h2 className="bold-font">Forgot Password</h2>
                    <Formik
                        initialValues={{
                            email: '',
                        }}
                        validationSchema={SignupSchema}
                        onSubmit={(values, actions) => {
                            // same shape as initial values
                            this.handleFormSubmission(values, actions);
                        }}
                    >
                        {({ 
                            isSubmitting,
                            handleSubmit,
                            handleChange,
                            handleBlur,
                            values,
                            touched,
                            errors,
                        }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <div className="form_container">
                                    <div className="form-group">
                                        <label htmlFor="email" className="semibold-font">Email Address</label>
                                        <Form.Control 
                                            type="text" 
                                            id="email" 
                                            name="email" 
                                            placeholder="Your Email Address" 
                                            value={values.email}
                                            onChange={handleChange}
                                            onBlur={handleBlur} 
                                            isInvalid={errors.email && touched.email}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.email}
                                        </Form.Control.Feedback>
                                    </div>
                                    <div className="row mt-5">
                                        <div className="col-12 text-center mb-3">
                                            <button 
                                                type="submit" 
                                                className="btn btn-primary"
                                            >
                                                {isSubmitting ? (
                                                    <Fragment>
                                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Please Wait...
                                                    </Fragment>
                                                ) : 'Submit'}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </Form> 
                        )}
                    </Formik>
                    <Alert 
                        color='primary' 
                        fade={false}
                        variant={this.state.alertVariant} 
                        onClose={() => this.closeAlert()} 
                        dismissible
                        show={this.state.alertVisible}
                    >
                    </Alert>                            
                </div>  
                <span style={{color:"black", textAlign:"center" }}>{this.state.alertMessage}</span>             
            </div>            
        )
    }
}
