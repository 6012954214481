import React from 'react';
import LeadSave from '../components/LeadSave';
import PageTitle from '../components/PageTitle';

export default function Lead() { 
    
    return (
        <React.Fragment>     
            <PageTitle>
                <div>
                    <div className="page-title">
                        Appointment                       
                    </div>
                </div>
            </PageTitle>         
            <div className="row">
                <div className="col-lg-12">                 
                    <LeadSave /> 
                </div>
            </div>
        </React.Fragment>
    )
}
