import React  from 'react';
import LeadTable from './LeadTable';
import '../index.css';
import '../table.css'

function LeadSearch () {        
       
    return (
        <React.Fragment>
            <LeadTable></LeadTable>
        </React.Fragment>
    );
}

export default LeadSearch;